<script setup>
// @ is an alias to /src

// import TextInput from '@/components/TextInput.vue'
// import CheckboxInput from '@/components/CheckboxInput.vue'
const playstoreLink =
  'https://play.google.com/store/apps/details?id=verdi.go.development'
</script>

<template>
  <div class="min-h-screen driver-bg">
    <div
      class="flex flex-col justify-between max-w-4xl gap-16 pt-8 pb-8 m-auto md:pt-20 md: md:flex-row md:max-w-6xl"
    >
      <div class="max-w-lg pt-0 text-white md:pt-20">
        <h1 class="mb-4 text-4xl font-bold md:mb-8 md:text-6xl">
          {{ $t('landing_hero.driving') }}
        </h1>
        <p class="text-lg">
          {{ $t('landing_hero.proposition') }}
        </p>
      </div>
      <!-- <div class="flex-1 dummy-div" /> -->
      <!-- Desktop  -->
      <div class="hidden max-w-lg bg-gray-100 md:block">
        <div class="flex text-sm">
          <button
            class="flex flex-col items-center justify-center px-6 py-4 text-sm font-medium bg-white border-t-2 border-secondary-50"
          >
            <span class="text-2xl material-symbols-outlined text-secondary-40">
              directions_car
            </span>
            {{ $t('side_box.wanna_drive') }}
          </button>
          <button
            class="flex flex-col items-center justify-center px-6 py-4 border-t-2 disabled:opacity-50"
            disabled
          >
            <span class="text-2xl material-symbols-outlined text-secondary-40">
              package
            </span>
            {{ $t('side_box.wanna_send') }}
          </button>
        </div>

        <div class="flex flex-col px-12 pt-6 pb-10 bg-white gap-y-5">
          <h2 class="text-xl font-semibold">
            {{ $t('side_box.registration') }}
          </h2>

          <a class="" :href="playstoreLink" target="_blank">
            <img
              class="h-12"
              src="@/assets/images/google-install.png"
              alt="Google Play"
            />
          </a>
          <p>{{ $t('side_box.or') }}</p>
          <ul class="text-sm steps">
            <li>{{ $t('side_box.open_camera') }}</li>
            <li>{{ $t('side_box.scan_qr') }}</li>
            <li>{{ $t('side_box.click_button') }}</li>
          </ul>
          <img
            :src="require('@/assets/images/qr-code.png')"
            class="w-48 h-48"
          />
          <!--          <p class="text-sm text-neutral-50">-->
          <!--            Ako QR ne radi upisite sljedeci link na adresnu traku u pregledniku-->
          <!--            na vasem mobilnom telefonu-->
          <!--          </p>-->
          <!--          <a-->
          <!--            href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"-->
          <!--            class="text-sm text-secondary-40"-->
          <!--            >https://verdi-go/app-download</a-->
          <!--          >-->
        </div>

        <!-- <form class="flex flex-col px-12 pt-6 pb-10 bg-white gap-y-5">
          <h2 class="text-xl font-semibold">Prijava za vozace</h2>
          <TextInput label="Ime i prezime" id="fullname" type="text" />
          <TextInput label="Email" id="email" type="email" />
          <TextInput label="Broj telefona" id="tel" type="tel" />
          <CheckboxInput id="terms">
            Prihvacam uvjete koristenja i pravila o privatnosti
          </CheckboxInput>
          <button type="submit" class="px-4 py-2 mt-4 text-white rounded bg-secondary-50">
            Prijavi se
          </button>
        </form> -->
      </div>
      <div class="max-w-lg md:hidden">
        <h2 class="mb-4 text-xl text-white">
          {{ $t('side_box.download_app') }}
        </h2>
        <a class="block w-48" :href="playstoreLink" target="_blank">
          <img
            class="w-full"
            src="@/assets/images/google-install.png"
            alt="Google Play"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.driver-bg {
  background: radial-gradient(
        58.61% 106.29% at 91.09% 1.63%,
        rgba(0, 0, 0, 0.34) 0%,
        rgba(0, 0, 0, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url('@/assets/images/hero.jpg');
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.dummy-div {
  height: 572px;
}

.steps {
  counter-reset: step;
}

.steps li {
  list-style-type: none;
  position: relative;
  padding-left: 2.5rem;
  margin-bottom: 1rem;
}

.steps li:before {
  content: counter(step);
  counter-increment: step;
  position: absolute;
  left: 0;
  top: -0.25rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: #000;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
</style>
