<script setup>
import ValuePropositionItem from '@/components/ValuePropositionItem.vue'
// TODO: finish proposition translation
const propositions = [
  {
    // landing_value_proposition.values.value_a.title
    // landing_value_proposition.values.value_a.description
    icon: 'payments',
    title: 'landing_value_proposition.values.value_a.title',
    description:
      'landing_value_proposition.values.value_a.description',
  },
  {
    icon: 'schedule',
    title: 'landing_value_proposition.values.value_b.title',
    description:
      'landing_value_proposition.values.value_b.description',
  },
  {
    icon: 'add_location',
    title: 'landing_value_proposition.values.value_c.title',
    description:
      'landing_value_proposition.values.value_c.description',
  },
]
</script>

<template>
  <div class="min-h-screen">
    <div
      class="flex flex-col justify-between h-full max-w-4xl gap-8 py-8 pb-16 m-auto md:pb-20 md:py-20 md:flex-row"
    >
      <div class="max-w-lg text-black ">
        <div class="sticky top-20">
          <h1 class="mb-4 text-4xl font-bold md:mb-8 md:text-6xl">
            {{ $t('landing_value_proposition.taxi_for_packages') }}
          </h1>
          <p class="text-lg">
            {{ $t('landing_value_proposition.sales_pitch') }}
          </p>
        </div>
        <div></div>
      </div>

      <div class="flex flex-col gap-4 md:gap-8">
        <ValuePropositionItem
          v-for="item in propositions"
          :key="item.title"
          :icon="item.icon"
          :title="$t(item.title)"
          :description="$t(item.description)"
          class=""
        />
      </div>
    </div>
  </div>
</template>

<style></style>
