<script setup>
function scrollToId(id) {
  const el = document.getElementById(id)
  if (el) {
    el.scrollIntoView({ behavior: 'smooth' })
  }
}
</script>

<template>
  <div class="border-t text-neutral-92 bg-neutral-10 border-neutral-80 pt-11">
    <div class="grid max-w-5xl grid-cols-12 m-auto gap-y-4 gap-x-6">
      <div class="col-span-12 text-2xl font-bold">
        Verdi <span class="text-secondary-60">GO</span>
      </div>
      <div class="max-w-xs col-span-4 text-sm text-neutral-50">
        {{ $t('footer.app_description') }}
      </div>
      <div class="flex flex-col col-span-3 gap-4 pb-4">

        <router-link to="/privacy">
          {{ $t('footer.privacy') }}
        </router-link>

        <router-link to="#driver-signup" @click="scrollToId('driver-signup')">
          {{ $t('footer.driver_signup') }}
        </router-link>
        <!-- <router-link to="/">Posiljatelji</router-link> -->
        <!-- <router-link to="/">Lorem ipsum</router-link> -->
        <router-link to="#faq" @click="scrollToId('faq')">
          {{ $t('footer.faq') }}
        </router-link>
      </div>
      <div class="flex flex-col col-span-3 gap-4 pb-4">
        <!-- <router-link to="/">Partneri</router-link> -->
        <!-- <router-link to="/">Uvijeti i odredbe</router-link> -->
        <!-- <router-link to="/">Pravila privatnosti</router-link> -->
      </div>
      <div class="col-span-12 py-4 border-t text-neutral-50 border-neutral-25">
        Verdi GO © 2022 - 2023
      </div>
    </div>
  </div>
</template>

<style></style>
