<template>
  <div class="min-h-screen pt-8 pb-16 md:py-32">
    <div class="h-full max-w-4xl m-auto">
      <h1 class="mb-12 text-4xl font-bold md:text-6xl text-secondary-40">{{ $t('faq.title')}}</h1>
      <div class="">
        <FAQItem
          v-for="item in questions"
          :key="item.question"
          :question="$t(item.question)"
          :answer="$t(item.answer)"
          :initialOpen="item.open"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import FAQItem from '@/components/FAQItem.vue'

const questions = [

  //

  //
  //

  //
  //
  {
    question: 'faq.questions.a.q',
    answer:
      'faq.questions.a.a',
  },
  {
    question: 'faq.questions.b.q',
    answer:
      'faq.questions.b.a',
  },
  {
    question: 'faq.questions.c.q',
    answer:
      'faq.questions.c.a',
  },
  {
    question: 'faq.questions.d.q',
    answer:
      'faq.questions.d.a',
  },
  {
    question: 'faq.questions.e.q',
    answer:
      'faq.questions.e.a',
    open: true,
  },
  {
    question: 'faq.questions.f.q',
    answer:
      'faq.questions.f.a',
  },
]
</script>

<style></style>
