<script setup>
import { useI18n } from 'vue-i18n'
const { locale } = useI18n({ useScope: 'global' })
</script>

<template>
  <select class="uppercase bg-transparent" v-model="locale">
    <option value="en" class="text-black">EN</option>
    <option value="hr" class="text-black">HR</option>
  </select>
</template>
