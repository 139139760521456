<script setup>
const conditions = [

  {
    icon: 'badge',
    title: 'driver_preconditions.preconditions.a.title',
    description: 'driver_preconditions.preconditions.a.description',
  },
  {
    icon: 'directions_car',
    title: 'driver_preconditions.preconditions.b.title',
    description: 'driver_preconditions.preconditions.b.description',
  },
  {
    icon: 'phone_android',
    title: 'driver_preconditions.preconditions.c.title',
    description: 'driver_preconditions.preconditions.c.description',
  },
]
</script>

<template>
  <div class="pt-8 pb-16 md:py-32">
    <div class="h-full max-w-3xl m-auto">
      <h1 class="mb-4 text-4xl font-bold md:mb-12 md:text-center md:text-6xl">Preduvjeti</h1>
      <div class="relative grid gap-4 md:grid-cols-3 gap-x-28">
        <div
          class="absolute hidden w-5/6 m-auto -translate-x-1/2 border-t-4 border-dotted md:block top-9 left-1/2"
        ></div>
        <div
          v-for="item in conditions"
          :key="item.title"
          class="relative flex flex-col items-center text-center"
        >
          <div class="flex items-center justify-center w-12 h-12 bg-white p-9">
            <span class="text-5xl md:text-5xl material-symbols-outlined text-secondary-40">
              {{ item.icon }}
            </span>
          </div>
          <h2 class="mb-1 text-xl font-bold md:text-2xl">{{ $t(item.title) }}</h2>
          <p class="text-gray-500">{{ $t(item.description) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
