<script setup>
// import { onBeforeMount } from 'vue';

// onBeforeMount(() => {
//   window.addEventListener('beforeinstallprompt', (e) => {
//     console.log('beforeinstallprompt')
//     e.preventDefault()
//     window.deferredPrompt = e
//   })
// })

// function handlePWAInstall() {
//   console.log('handlePWAInstall')
//   const deferredPrompt = window.deferredPrompt
//   if (deferredPrompt) {
//     deferredPrompt.prompt()
//     deferredPrompt.userChoice.then((choiceResult) => {
//       if (choiceResult.outcome === 'accepted') {
//         console.log('User accepted the A2HS prompt')
//       } else {
//         console.log('User dismissed the A2HS prompt')
//       }
//       window.deferredPrompt = null
//     })
//   }
// }
const playstoreLink =
  'https://play.google.com/store/apps/details?id=verdi.go.development'
</script>

<template>
  <div class="pt-8 pb-8 text-white the-gradient md:py-0">
    <div
      class="flex flex-col max-w-5xl gap-4 m-auto md:items-center md:flex-row"
    >
      <img
        src="@/assets/images/pwa-peek.png"
        alt=""
        class="self-end hidden mr-4 w-28 md:block"
      />
      <div class="flex-1">
        <h1 class="mb-2 text-2xl font-bold">{{ $t('download_app.title') }}</h1>
        <p>
          {{ $t('download_app.description') }}
        </p>
      </div>
      <div class="flex gap-2">
        <a class="" :href="playstoreLink" target="_blank">
          <img
            class="h-12"
            src="@/assets/images/google-install.png"
            alt="Google Play"
          />
        </a>
        <!-- <button class="" @click="handlePWAInstall">
        <img
          class="h-12"
          src="@/assets/images/pwa-install.png"
          alt="Progressive web app"
        />
        </button> -->
        <!-- <button class="px-4 py-2 text-black bg-white rounded">
          Instaliraj aplikaciju
        </button> -->
      </div>
    </div>
  </div>
</template>

<style></style>
