<template>
<div class="section">
  <h2 id="about">O nama</h2>
  <p>VerdiGo je prva hrvatska aplikacija za rapidne dostave, prilagodjena Vašim potrebama kao korisnika.</p>
  <p>Nakon uspješne registracije i prijave, ulazite u samu aplikaciju koja je podijeljena na 3 dijela, listuvoznji, mapu i Vaš korisnicki profil. Na Vasem korsinickom profilu se nalazi gumb ‘Počni voziti’ koji signalizira da ste aktivni i spremni preuzimati voznje. Taj gumb morate odabrati da biste mogli dostavljati. Ako taj gumb nije odabran, i dalje imate pristup svim prisutnim uslugama, kao pregledavanje raspoloživih vožnji, korištenje Vaseg korisničkog profila, pregled zarade itd. OSIM prihvaćanja dostava i obavljanja istih.</p>
  <p>Za svaku dostavu je moguce da bude više od jedan paket koji je potreban dostaviti na istu adresu kupca, samim tim se ti paketi moraju pokupiti sa vise od jedne adrese u Vasem radijusu, no spadaju pod jednu jedinstvenu dostavu, s obzirom da je u pitanju isti kupac i njegova ista narudzba. Naravno, to se dodatno i placa, bez brige 🙂</p>
  <p>Evo kratak pregleda potrebnih koraka za uspješnu dostavu:</p>
  <ul>
    <li>Uključite ‘počni voziti’ gumb na ‘profilu’</li>
    <li>Pregledajte ‘mapu’ za raspoložive vožnje u Vašoj blizini</li>
    <li>Vožnju koju želite obaviti, odaberite gumbom ‘prihvati dostavu’ (možete ih imati 3 rezervirane). Tim gumbom, vožnja se u našem sistemu ažurira.</li>
    <li>Odaberete ‘navigiraj’ za samu navigaciju, ukoliko Vam je potrebna.</li>
    <li>U slučaju dostave sa više od jednom adresom s koje se preuzima paket, moći ćete odabrati svoj raspored prikupa paketa, ovisno o tome koje su Vam adrese najbliže. Taj raspored moze nekad biti 123, a nekad 231, napr. no uspjesna dostava je moguća tek kad se svi paketi pokupe. Kojim god redom idete, mozete označiti da ste preuzeli pakete pod ‘preuzmi’ ovisno o Vašem redoslijedu. Taj gumb nećete moći označiti ako niste u dozvoljenom radijusu od adrese prikupa paketa, nadamo se da je jasno i zašto…</li>
    <li>Uspješno obavite dostavu, plaćeni ste, i idemo dalje 🙂</li>
  </ul>

</div>
  <div class="section">
    <h1 id="opći-uvjeti-korištenja-i-politika-privatnosti">Opći uvjeti korištenja i politika privatnosti</h1>
    <h2 id="o-nama">O NAMA</h2>
    <p>Mi smo: VerdiGo (LMB 1759 Export d.o.o.)<br>
      Sjedište: Susedgradska 3, 10000 Zagreb<br>
      Email: <a href="mailto:lucija@verdi-farm.com">lucija@verdi-farm.com</a><br>
      Temeljni kapital uplaćen u cijelosti 20.000 kn<br>
      Žiro račun: HR6124020061100964353 otvoren kod Erste banka d.d.</p>
    <p>OIB: 23568488873</p>
    <h2 id="definicije">1. DEFINICIJE</h2>
    <p>1.1. VerdiGo dalje može biti naveden kao “mi”, “naše” ili “nama").</p>
    <p>1.2. VerdiGo Usluge – usluge koje pruža VerdiGo, uključujući pružanje i održavanje VerdiGo Aplikacije, VerdiGo Platforme, In-app plaćanja, korisničke podrške, komunikacije između Vozača i Prodavača i ostale slične usluge.</p>
    <p>1.3. VerdiGo Aplikacija – aplikacija za pametni telefon ili internet aplikacija kojom Vozači i Prodavačo traže i primaju Usluge prijevoza.</p>
    <p>1.4. VerdiGo Platforma – tehnologija koja spaja Prodavače s Vozačima kako bi im pomogla da se njihovi proizvodi/artikli efikasno i adekvatno dostave po gradu.</p>
    <p>1.5. Prodavač – osoba koja traži Usluge transporta i dostave za svoje proizvode/artikle koristeći VerdiGo Platformu.</p>
    <p>1.6. Vozač (također „Vi") – osoba koja pruža Uslugu prijevoza/dostave putem VerdiGo Platforme.</p>
    <p>1.7. Ugovor – ovaj ugovor između vozača i VerdiGo u vezi s korištenjem VerdiGo usluge koji se sastoji od:</p>
    <p>1.7.1. ovih Općih uvjeta;</p>
    <p>1.7.2. posebnih uvjeta prikazanih u VerdiGo Aplikaciji, npr. u vezi s informacijama o cijenama ili opisima usluga;</p>
    <p>1.7.3. smjernice za Vozače; te</p>
    <p>1.7.4. drugih uvjeta koji se spominju u ovom Ugovoru i koji se mogu povremeno mijenjati.</p>
    <p>1.8. Naknada za vožnju – naknada koju je Prodavač dužan platiti Vozaču za pružanje Usluge prijevoza/dostave.</p>
    <p>1.9. Usluge prijevoza– usluga prijevoza koju Vozač pruža Prodavaču čiji je zahtjev za vožnju Vozač prihvatio putem VerdiGo Aplikacije.</p>
    <h2 id="sklapanje-ugovora">2. SKLAPANJE UGOVORA</h2>
    <p>2.1. Prije korištenja VerdiGo usluga morate se prijaviti na način da u zahtjevu za prijavu na internetskoj stranici dostavite tražene podatke i učitate potrebnu dokumentaciju na način na koji Vas zatražimo. Možete se prijaviti kao fizička osoba ili obrtnik. Po uspješnom završetku prijave, izdat ćemo Vam osobni račun dostupan putem korisničkog imena i zaporke. Klikom na gumb “Prijava” koji se nalazi na dnu zahtjeva za prijavu, izjavljujete i jamčite da:</p>
    <p>2.1.1 prema važećim pravnim aktima, imate pravo sklopiti ugovor s nama za korištenje VerdiGo Platforme radi pružanja Usluge prijevoza/dostave;</p>
    <p>2.1.2. pažljivo ste proučili, u potpunosti razumjeli i pristajete biti obvezani ovim Općim uvjetima, uključujući i sve obveze koje proizlaze iz ovdje navedenog i Ugovora;</p>
    <p>2.1.3. sve informacije koje ste nam predočili i dostavili su istinite, točne i potpune;</p>
    <p>2.1.4. održavat ćete VerdiGo Račun Vozača točnim i informacije na profilu uvijek ažuriranima;</p>
    <p>2.1.5. nećete ovlastiti druge osobe da koriste Vaš VerdiGo Račun Vozača, niti ga prenijeti ili dodijeliti drugoj osobi;</p>
    <p>2.1.6. nećete koristiti VerdiGo Uslugu za neovlaštene ili nezakonite svrhe niti narušiti ispravno funkcioniranje VerdiGo usluga;</p>
    <p>2.1.7. cijelo vrijeme ćete se u potpunosti pridržavati svih zakona i propisa koji se primjenjuju u državi u kojoj pružate Uslugu prijevoza, uključujući (ali ne ograničavajući se na) zakone koji uređuju usluge putničkog prijevoza;</p>
    <p>2.2. Prilikom ispunjavanja podataka o plaćanju nakon registracije dužni ste dostaviti Vaše bankovne podatke. Ako ste pravna osoba, morate upisati bankovni račun društva. Mi prenosimo Naknade za plaćanje unutar Aplikacije na bankovni račun koji ste unijeli. Mi nismo odgovorni ni za kakve netočne transakcije u slučaju da ste unijeli pogrešne bankovne podatke.</p>
    <p>2.1.8. svjesni ste da VerdiGo ima pravo podijeliti s nadležnim poreznim tijelima sve informacije propisane Direktivom Vijeća (EU) 2021/514 od 22. ožujka 2021. o izmjeni Direktive 2011/16/EU o administrativnoj suradnji u području oporezivanja, uključujući, ali ne ograničavajući se na bilo koju naknadu uplaćenu ili dodijeljenu Vozaču u vezi s aktivnostima koje se provode putem VerdiGo Platforme. Ako ne pružite podatke potrebne prema gore navedenoj Direktivi nakon dva podsjetnika nakon početnog zahtjeva od strane VerdiGo, ali ne prije isteka 60 dana, VerdiGo ima pravo (i) ukinuti Vaš račun i spriječiti Vas da ponovno registrirate na VerdiGo Platformi ili (ii) uskratiti plaćanje Naknade za vožnju sve dok ne pružite tražene podatke.</p>
    <p>2.3. VerdiGo zadržava pravo tražiti ispunjenje odredjenih uvjeta s Vaše strane. Ovi uvjeti mogu uključivati dostavu pisanih potvrda ili ovjerenih preslika kaznenih evidencija, fiskalnih certikata, valjane vozačke dozvole, potvrde o zadovoljavajućem tehničkom stanju vozila, završetak tečaja, posjedovanje mobilnog uređaja koji podržava GPS i druge uvjete navedene u odgovarajućoj e-mailu. Nepoštivanje spomenutih uvjeta i odredaba može rezultirati raskidom Ugovora i gubitkom prava na korištenje VerdiGo Usluge.</p>
    <p>2.5. . <strong>Registriranje računa kao Pravna osoba</strong> (tj.kao društvo). Smatrat ćete se pravnom osobom, ako je primatelj naknada označen kao pravna osoba u detaljima plaćanja (koji su dostupni na VerdiGo Računu Vozača). U tom slučaju, navedena pravna osoba se smatra pružateljem usluga prijevoza i ugovorna strana ovih Općih uvjeta, Ugovora i svih dodatnih ugovora. Samo određena fizička osoba naznačena u postupku prijave može doista pružati Usluge prijevoza. Navedena fizička osoba može koristiti račun Vozača samo ako je pročitala i pristala biti obvezana ovim Općim uvjetima i bilo kojoj daljnjoj dokumentaciji koja je dio Ugovora. PRAVNA OSOBA NAVEDENA U PODACIMA ZA PLAĆANJE I FIZIČKA OSOBA KOJA DOISTA PRUŽA USLUGU PRIJEVOZA KORISTEĆI VerdiGo RAČUN SOLIDARNO SU ODGOVORNE ZA SVE POVREDE OPĆIH UVJETA I SPORAZUMA KOJE POČINI VOZAČ.</p>
    <p>2.6. <strong>Registracija</strong> <strong>VerdiGo</strong> <strong>Računa Vozača kao društva s flotom vozila</strong> (Fleet Društvo). Nakon sklapanja zasebnog ugovora, Fleet Društvo može samo registrirati račune za svoje zaposlenike i / ili pružatelje usluga. U tim slučajevima Fleet Društvo obvezno je osigurati da njegovi zaposlenici i / ili pružatelji usluga udovoljavaju zahtjevima Općih uvjeta, Ugovora i svih dodatnih ugovora te da su suglasni postupati u skladu i biti obvezani tim uvjetima i odredbama. Fleet Društvo i njegovi zaposlenici i / ili pružatelji usluga ostaju solidarno odgovorni za svako kršenje koje počini takav zaposlenik i / ili pružatelj usluga.</p>
    <h2 id="pravo-korištenja-verdigo-verdigo-računa-vozača">3. PRAVO KORIŠTENJA VerdiGo VerdiGo RAČUNA VOZAČA</h2>
    <p>3.1. <strong>Dozvola</strong> <strong>za korištenje</strong> <strong>VerdiGo</strong> <strong>Aplikacije i</strong> <strong>VerdiGo</strong> <strong>Računa Vozača</strong>. Ovime Vam dodjeljujemo dozvolu za korištenje VerdiGo Aplikacije i VerdiGo Računa Vozača. Dozvola Vam ne daje pravo na podlicenciranje ili prijenos bilo kakvih prava trećoj osobi. Neovisno o gore navedenom i ako je tako posebno dogovoreno, Fleet Društva mogu podlicencirati dozvole za VerdiGo Aplikaciju i VerdiGo Račun Vozača članovima svoje flote.</p>
    <p>3.2. Pri korištenju VerdiGo aplikacije i / ili VerdiGo računa vozača ne smijete:</p>
    <p>3.2.1. dekompilirati, obrnutim postupkom konstruirati ili na neki drugi način pokušati doći do izvornog koda VerdiGo Aplikacije, VerdiGo Računa Vozača ili drugog softvera VerdiGo;</p>
    <p>3.2.2.izmijeniti VerdiGo Aplikaciju ili VerdiGo Račun Vozača na bilo koji način ili oblik ili koristiti modificirane inačice VerdiGo Aplikacije ili VerdiGo Računa Vozača;</p>
    <p>3.2.3. prenositi datoteke koje sadrže viruse, oštećene datoteke ili bilo koji drugi program koji mogu oštetiti ili negativno utjecati na operacije na VerdiGo Platformi;</p>
    <p>3.2.4. pokušati neovlašteno pristupiti VerdiGo Aplikaciji, VerdiGo Računu Vozača ili bilo kojoj drugoj VerdiGo Usluzi.</p>
    <p>3.3. Ovdje navedena dozvola automatski se ukida istodobno s otkazom Ugovora. Nakon prestanka važenja Ugovora morate odmah prestati koristiti VerdiGo Aplikaciju i VerdiGo Račun Vozača i imamo pravo blokirati i izbrisati Vaš VerdiGo Račun Vozača bez prethodne obavijesti.</p>
    <p>3.4. <strong>Upotreba oznake</strong> <strong>VerdiGo</strong>. Dodatno, zadržavamo pravo Vam dati oznake, naljepnice, ili druge znakove koji se odnose na brend VerdGo ili na neki drugi način naznačuju da koristite VerdiGo Platformu. U tom slučaju Vam dajemo neekskluzivnu i neprenosivu dozvolu, koja se ne može podlicencirati, za korištenje takvih znakova isključivo da biste naznačili da pružate Usluge prijevoza putem VerdiGo Platforme. Nakon raskida Ugovora, morate odmah ukloniti i baciti sve znakove koji se odnose na brend VerdiGo.</p>
    <h2 id="pružanje-usluga-prijevozadostave">4. PRUŽANJE USLUGA PRIJEVOZA/DOSTAVE</h2>
    <p>4.1. <strong>Obveze vozača</strong>. Ovim jamčite da ćete Usluge prijevoza/dostave pružati u skladu s Općim uvjetima, Ugovorom, kao i zakonima i propisima koji su mjerodavni u Republici Hrvatskoj, zemlji u kojoj pružate Usluge prijevoza. Napominjemo da ste u cijelosti odgovorni za svako kršenje bilo kojeg nacionalnog zakona i propisa u vezi s pružanjem Usluge prijevoza.</p>
    <p>4.2. Morate posjedovati sve dozvole (uključujući i valjanu vozačku dozvolu), licencije, osiguranje automobila, osiguranje od odgovornosti (ako je primjenjivo), registracije, potvrde i ostalu dokumentaciju koja je potrebna u skladu s mjerodavnim zakonodavstvom primjenjivim na pružanje Usluge prijevoza. Vaša je obveza održavati valjanost svih gore navedenih dokumenata. VerdiGo zadržava pravo da od Vas zatraži predočenje dokaza i podnošenje na pregled svih potrebnih licencija, dozvola, odobrenja, ovlasti, registracija i potvrda.</p>
    <p>4.3. Usluge prijevoza/dostave morate pružati profesionalno u skladu s poslovnom etikom koja se primjenjuje na pružanje takvih usluga i nastojati izvršiti zahtjev Prodavača u najboljem interesu Prodavača. Između ostalog,</p>
    <p>&lt;![if !supportLists]&gt;(i) &lt;![endif]&gt;morate ići rutom koja je najjeftinija za Prodavača</p>
    <p>&lt;![if !supportLists]&gt;(ii) &lt;![endif]&gt;(ii) ne smijete izvršiti nikakva neovlaštena stajanja,</p>
    <p>(iv) morate se pridržavati svih mjerodavnih prometnih zakona i propisa, tj. ne smijete izvršiti nikakve radnje koje bi mogle ometati vožnju ili uočavanje prometnih uvjeta, uključujući držanje telefona u ruci dok je vozilo u pokretu, te</p>
    <p>(v) morate voditi posebnog računa o tome da svojim radnjama ni na koji način ne ugrozite sigurnost proizvoda/artikla tijekom vožnje i/ili tijekom neposrednog kontakta s njima.</p>
    <p>4.4. Zadržavate isključivo pravo utvrđivanja kada želite pružati Usluge prijevoza. Po vlastitom nahođenju prihvatit ćete, odbiti ili zanemariti zahtjeve za Usluge prijevoza/dostave koje su izvršili Prodavači.</p>
    <p>4.5. <strong>Troškovi koji Vam nastanu tijekom pružanja Usluga prijevoza</strong>. Obvezni ste o vlastitom trošku osigurati i održavati svu opremu i sredstva potrebna za pružanje Usluga prijevoza, uključujući automobil, pametni telefon, itd. Također ste odgovorni za plaćanje svih troškova koji Vam nastanu tijekom pružanja Usluga prijevoza, uključujući, a ne ograničavajući se na, gorivo, troškove paketa podatkovnog prometa, pristojbe, amortizaciji vozila, osiguranje, odgovarajući porez na dobit i plaće, itd. Napominjemo da korištenje VerdiGo aplikacije može izazvati potrošnju velike količine mobilnih podataka u Vašem paketu podatkovnog prometa. Stoga Vam preporučujemo da se pretplatite za paket podatkovnog prometa s neograničenim ili vrlo visokim kapacitetom uporabe podatkovnog prometa.</p>
    <p>4.6. <strong>Naknada za vožnju</strong>. Imate pravo naplatiti Naknadu za vožnju u svakom slučaju kada prihvatite zahtjev Prodavača na VerdiGo platformi te izvršite Uslugu prijevoza/dostave na način na koji je naručena (tj. Naknade za vožnju/dostavu). Naknada za vožnju/dostavu izračunava se na temelju utvrđene osnovice Naknade za vožnju/dostavu, udaljenosti određene vožnje na način na koji ju je utvrdio uređaj na temelju GPS-a i trajanja određene vožnje. Osnovna Naknada za vožnju/dostavu može varirati ovisno o situaciji na lokalnom tržištu. Možete pregovarati o Naknadi za vožnju tako da nam pošaljete odgovarajući zahtjev koji je potpisan ili digitalno ili vlastoručno.</p>
    <p>4.9. VerdiGo zadržava pravo smanjiti, prilagoditi ili poništiti Naknadu za vožnju u slučaju kada imamo opravdani razlog sumnjati u prijevaru ili neodgovorno izvršavaknje usluga ili Prodavač podnese pritužbu zbog Vašeg kršenja pravila. VerdiGo svoje pravo umanjenja ili poništenja Naknada za vožnju koristit će isključivo na razuman, opravdan i zakonit način.</p>
    <p>4.12. <strong>Računi</strong>. Nakon svakog uspješnog pružanja Usluge prijevoza, VerdiGo će izraditi i proslijediti račun Prodavaču koji se sastoji od sljedećih informacija: tvrtke/naziva Pravne osobe ili obrtnika, mjesta poslovanja, imena i prezimena Vozača, slike Vozača, br. dozvole za uslugu (ako je primjenjivo), registracijskog broja vozila, datuma, vremena, početne i završne lokacije, trajanja i dužine, Naknade za vožnju i Naknade za vožnju koja je plaćena za pružanje Usluge prijevoza. Račun za svako pružanje Usluge prijevoza dostupan Vam je putem VerdiGo računa vozača.</p>
    <p>4.13. <strong>Naknada za otkazivanje i naknada za čekanje</strong>. Prodavač može otkazati zahtjev za uslugu prijevoza/dostave koju je vozač prihvatio putem VerdiGo Aplikacije.</p>
    <p>4.14. Napominjemo da ne preuzimamo nikakvu odgovornost za izravnu ili neizravnu štetu u vezi s čišćenjem ili održavanjem vozila koju je uzrokovao proizvod/artikal Prodavača.</p>
    <p>4.15. <strong>Vaše porezne obveze</strong>. Ovime potvrđujete da ste obvezni u potpunosti ispuniti sve porezne obveze koje za vas nastanu na temelju primjenjivih zakona u vezi s pružanjem Usluga prijevoza, uključujući</p>
    <p>(i) plaćanje poreza na dohodak, poreza na socijalno osiguranje/davanja ili bilo koji druge primjenjive poreze i</p>
    <p>(ii) ispuniti sve obveze prema zaposlenicima i prijave kod porezne uprave za računovodstvene obračune i prijenose pri odgovarajućim državnim tijelima kako je propisano mjerodavnim zakonima.</p>
    <p>U slučaju da nam nadlezno porezno tijelo dostavi valjani zahtjev kojim traži da im dostavimo podatke u vezi Vašeg poslovanja, možemo nadležnim poreznim tijelima dostaviti podatke u vezi Vašeg poslovanja u mjeri u kojoj je predviđeno valjanim zakonskim aktima.</p>
    <p>Nadalje, obvezni ste pridržavati se svih mjerodavnih poreznih propisa koji mogu biti primjenjivi u vezi s pružanjem Usluga prijevoza. Ovime ste suglasni naknaditi VerdiGo sve državne pristojbe, potraživanja, plaćanja, novčane kazne ili druge porezne obveze koje nastanu VerdiGo u vezi s obvezama koje proizlaze iz mjerodavnih poreznih propisa koje niste ispunili (uključujući plaćanje poreza na dohodak i socijalnih davanja/poreza).</p>
    <p>4.16. <strong>Ovlaštenje vozača da izda račun</strong>. VerdiGo ima pravo izdati račun u vaše ime Prodavaču kako bi vam naknadili bilo koju Naknade za vožnju, ugovornu kaznu ili naknadu koju vam proslijedi VerdiGo.</p>
    <h2 id="verdigo-naknade"><strong>5.</strong> <strong>VerdiGo</strong> <strong>NAKNADE</strong></h2>
    <p><strong>5.1.</strong> <strong>U korištenju VerdiGo usluge, VerdiGo Vam ne zaracunava nikakvu naknadu, osim u slucaju kašnjenja vožnje/dostave u trajanju od više od 6 sati. U tom slucaju, VerdiGo zaračunava naknadu od 25% na svaki sat kašnjenja, te Vozaču za toliko umanjuje naknadu za vožnju isplaćenu od Prodavača. VerdiGo zadržava pravo uspostavljanja i primjene zaračunavnja opće naknade na svaku vožnju/dostavu, ali tek nakon odgovarajuceg informiranja Vozača, izmjene Uvjeta poslovanja i suglasnosti/pristanka Vozača na iste.</strong></p>
    <p><strong>U slučaju u kojem je Prodavač Verdi, radijus je garantirano manji od ili jednak 20 km. Taj uvijet je podlozan promjenama uvjetima poslovanja samo Verdi Prodavača.</strong></p>
    <h2 id="korisnička-podrška"><strong>7. KORISNI<strong><strong>ČKA PODRŠ</strong></strong>KA</strong></h2>
    <p>Pružamo Korisničku podršku vozača u vezi s korištenjem VerdiGo Usluga. Imamo pravo prestati pružati usluge korisničke podrške ako kasnite s bilo kojim plaćanjima više od 5 kalendarskih (dana).</p>
    <h2 id="ocjene-i-aktivnosti"><strong>8. OCJENE I AKTIVNOSTI</strong></h2>
    <p>8.1. Kako biste Prodavačima osigurali visoko kvalitetne usluge i pružili dodatna osiguranja, ovime prihvaćate da Vam Prodavač može dati ocjenu i povratnu informaciju u vezi s kvalitetom usluge prijevoza koju ste pružili. Vaša prosječna ocjena (prosječni rejting) može biti povezana na Vaš VerdiGo Račun Vozača i dostupna Prodavačima na VerdiGo Aplikaciji. Ako smatramo da ocjena ili komentar nije dan u dobroj vjeri, ta ocjena ili komentar ne mora biti uvrštena u izračun Vaše ocjene.</p>
    <p>8.2. Kako bismo Prodavačima pružili pouzdane usluge, možemo odrediti minimalnu prosječnu ocjenu koju Vozači moraju dobiti i održavati. Ova ocjena se može koristiti kako bi se osigurala sigurnost i izbjeglo nedolično ponašanje na našoj platformi. Ako, nakon naše primjerene obavijesti ne povećate svoj prosječnu ocjenu do minimalne razine unutar opisanog vremenskog razdoblja, Vaš će VerdiGo Račun Vozača može biti obustavljen privremeno ili trajno. Možemo poništiti obustavu Vašeg računa, ako je uzrokovan nekim vanjskim okolnostima ili je ustanovljeno da je obustava uzrokovana greškom u sustavu ili lažnim ocjenama.</p>
    <h2 id="ispitivanje-tržišta-i-promidžbe">9. ISPITIVANJE TRŽIŠTA I PROMIDŽBE</h2>
    <p>9.1. <strong>Ispitivanje tržišta</strong>. Možemo Vam, putem VerdiGo Aplikacije, VerdiGo Računa Vozača, SMS-a, e-mail ili drugim putem, poslati upit u vezi ispitivanja tržišta, kako bismo povećali osviještenost o vremenu u kojem je potražnja od strane Prodavača najveća. Takva ispitivanja tržišta imaju narav preporuke i ne predstavljaju nikakvu obvezu. Budući da su procjene ispitivanja tržišta utemeljene na prijašnjim statistikama, ne možemo garantirati da će stvarna situacija na tržištu odgovarati procjenama danima u ispitivanju tržišta.</p>
    <p>9.2. <strong>Promid<strong><strong>žbe za P</strong></strong>rodavače</strong>. Možemo također povremeno organizirati različite kampanje za Prodavače, kako bismo promovirali VerdiGo Platformu.</p>
    <h2 id="odnos-između-vas-nas-i-prodavača">10. ODNOS IZMEĐU VAS, NAS I PRODAVAČA</h2>
    <p>10.1. Ovime prihvaćate i suglasni ste da pružamo usluge informacijskog društva i ne pružamo Usluge Prijevoza/Dostave. Pružanjem usluga VerdiGo Platforme i VerdiGo Usluga, djelujemo kao tržišno mjesto povezujući Prodavače s Vozačima, da bismo im pomogli u učinkovitijem transportu proizvoda/artikala po gradovima i dostavi istih. Prihvaćate da pružate Usluge prijevoza/dostave na temelju ugovora za prijevoz/dostave i da pružate Usluge prijevoza ili neovisno ili putem društva kao gospodarsku i profesionalnu aktivnost. VerdiGo, kao pružatelj VerdiGo Aplikacije djeluje kao komercijalni zastupnik Vozača i Prodavača te između ostalog, prihvaća plaćanja Prodavače i prosljeđuje ih Vozaču.</p>
    <p>10.2. Suglasni ste da nije sklopljen nikakav ugovor o radu niti uspostavljen radni odnos između Vas i nas. Također, prihvaćate da ne postoje zajednički pothvati (joint venture) niti partnerstva između Vas i nas. Ne smijete djelovati kao zaposlenik, posrednik ili zastupnik nas niti se obvezivati bilo kojim ugovorom u naše ime. Ako zbog primjene mjerodavnih zakona ili drugim putem, budete smatrani zaposlenikom nas ovime ste suglasni da ćete odbaciti pravo na podnošenje bilo kakvih zahtjeva koji bi proizašli kao posljedica tako impliciranog radnog odnosa.</p>
    <p>10.3. Ne smijete prenijeti svoja prava i obveze koje proizlaze iz Općih uvjeta ili Ugovora na bilo koju treću stranu.</p>
    <h2 id="obrada-osobnih-podataka-pristup-podacima"><strong>11. OBRADA OSOBNIH PODATAKA, PRISTUP PODACIMA</strong></h2>
    <p>11.1. VerdiGo ima pristup svim osobnim podacima i drugim podacima danim ili generiranim u vezi s Vašim korištenjem VerdiGo Usluga. VerdiGo će poduzeti sve razumne i zakonite korake kako bi osigurao povjerljivost takvih podataka i pridržavao se svih mjerodavnih Pravila o privatnosti i zakona kad god ti podaci sadrže osobne podatke. Osim ako je drugačije određeno važećim Pravilima o privatnosti i zakonima, VerdiGo zadržava pristup takvim podacima i nakon raskida Ugovora između Vas i VerdiGo.</p>
    <p>11.2. Imate pristup osobnim i drugim podacima koje ste sami dostavili ili koji su generirani u vezi s Vašim korištenjem VerdiGo Usluga u mjeri koja Vam je dostupna unutar VerdiGo Računa Vozača putem VerdiGo Aplikacije. Poduzet ćete sve razumne korake kako biste osigurali povjerljivost takvih podataka i pridržavali se primjenjivih Pravila o privatnosti i zakona sve dok i u mjeri u kojoj ti podaci sadrže osobne podatke putnika.</p>
    <h2 id="odgovornost"><strong>12. ODGOVORNOST</strong></h2>
    <p>12.1. VerdiGo Platforma djeluje na principu “tako je - kako je” i “ako je dostupno”. Ne izjavljujemo, ne jamčimo, niti garantiramo da će pristup VerdiGo Aplikaciji biti neprekinut ili bez greške.</p>
    <p>12.2. Do najveće moguće dozvoljene mjere propisane mjerodavnim zakonima, ni mi, niti predstavnici, direktori ili zaposlenici nismo odgovorni za bilo kakav gubitak ili štetu koja Vam može nastati kao rezultat korištenja VerdiGo Usluga uključujući, ali ne ograničavajući se na:</p>
    <p>12.2.1. bilo koju izravnu ili neizravnu štetu na imovini ili novčani gubitak;</p>
    <p>12.2.2. izmaklu dobit ili gubitak planiranih ušteda;</p>
    <p>12.2.3. gubitak posla, ugovora, kontakata, ugleda, goodwill-a, reputacije ili bilo koji gubitak koji može nastati zbog prekida poslovanja;</p>
    <p>12.2.4. gubitak ili netočnost podataka; i</p>
    <p>12.2.5. bilo koju drugu vrstu gubitka ili štete.</p>
    <p>12.3. Naša financijska odgovornost u vezi s povredom Općih uvjeta ograničena je na 20 eura. Imat ćete pravo na zahtjev za naknadu štete samo u slučaju ako smo namjerno prekršili ugovor.</p>
    <p>12.4. Nećemo biti odgovorni za radnje ili izostanak radnji Prodavača.</p>
    <p>12.5. U cijelosti ste odgovorni za povredu Općih uvjeta, Ugovora ili drugih mjerodavnih zakona ili propisa te morate odmah nakon primitka naših zahtjeva ili zahtjeva državnih vlasti prestati i otkloniti tu povredu. Obeštetit ćete nas za sve izravne i/ili neizravne gubitke i/ili štetu, gubitak dobiti, troškove, ugovorne kazne, novčane kazne koje nama mogu nastati u s vezi povredom Općih uvjeta, Ugovora, mjerodavnih zakona i propisa. Ako Prodavac istakne bilo kakvo potraživanje prema nama u vezi s pružanjem Usluga prijevoza, tu štetu ste nam obvezni u potpunosti nadoknaditi u roku od 7 (sedam) dana od dana primitka dotičnog zahtjeva od nas. U slučaju isticanja bilo kakvih potraživanja prema Vama, obvezni ste nam nadoknaditi sve troškove u vezi s procjenom štete i podnošenjem zahtjeva za naknadom te štete.</p>
    <h2 id="trajanje-blokada-i-raskid"><strong>13. TRAJANJE, BLOKADA I RASKID</strong></h2>
    <p>13.1. Odredbe izričito navedene u ovim Općim uvjetima stupaju na snagu predajom zahtjeva za registraciju. Ugovori i druge odredbe će stupiti na snagu kada Vam budu dostavljeni određeni dokument ili poruka, a Vi počnete ili nastavite pružati Uslugu prijevoza na VerdiGo Platformi.</p>
    <p>13.2. Ugovor možete raskinuti u bilo kojem trenutku na način da obavijestite VerdiGo najmanje 7 (sedam) dana unaprijed, nakon čega će prestati Vaše pravo korištenja VerdiGo Platforme i VerdiGo Usluga. VerdiGo može bilo kada i iz bilo kojeg razloga po vlastitom nahođenju raskinuti Ugovor na način da Vas obavijeste najmanje 24 sata unaprijed.</p>
    <p>13.3. VerdiGo ima pravo trenutno raskinuti Ugovor i zabraniti Vaš pristup VerdiGo Platformi bez bilo kakve prethodne obavijesti u slučaju da povrijedite Opće uvjete ili Ugovor, bilo koje mjerodavne zakone ili propise, narušite ugled VerdiGo ili prouzročite štetu brendu VerdiGo, ugledu ili poslovanju na način kako je to određeno prema vlastitom nahođenju VerdiGo. U prethodno navedenim slučajevima možemo vam, prema vlastitom nahođenju, zabraniti registriranje novog Računa vozača.</p>
    <p>13.4. Možemo trenutno zabraniti Vam pristup VerdiGo Aplikaciji i VerdiGo Računu Vozača tijekom razdoblja istrage, ako sumnjamo na povredu Ugovora ili prijevarne aktivnosti s vaše strane. Blokada pristupa će biti uklonjena kada istragom budu uklonjene takve sumnje.</p>
    <p>13.5. Cilj nam je svim Prodavačima pružiti najvišu razinu usluge, stoga nadziremo aktivnosti Vozača na VerdiGo Platformi. Ako ne ispunite minimalne uvjete u pružanju usluga, kao što je minimalni rejting i ocjena aktivnosti, ovlašteni smo trenutno raskinuti Ugovor bez prethodne obavijesti.</p>
    <p>13.6. Dodatni zahtjevi i zaštitne mjere predviđene Uredbom (EU) 2019/1150 (Uredba) primjenjivat će se ako raskid Ugovora ili blokiranje pristupa VerdiGo Platformi utječe na prava Vozača ili Fleet Društva koje koristi VerdiGo Usluge za pružanje Prijevozne usluge u državi članici Europske unije ili Europskog gospodarskog prostora (država članica).</p>
    <p>13.7. Vozač i Fleet Društvo iz članka 13.6 (Poslovni korisnik koji posluje u državi članici) ima pravo osporiti raskid Ugovora, blokiranje i drugo navodno kršenje Uredbe od strane VerdiGo, u skladu s Internom pritužbom - Rukovanje pravilima sustava za poslovne korisnike VerdiGo.</p>
    <h2 id="izmjene"><strong>14. IZMJENE</strong></h2>
    <p>14.1. VerdiGo zadržava pravo izmijeniti ove Opće uvjete i pravila u bilo kojem trenutku objavom izmijenjene verzije na svojoj webstranic/aplikaciji i dostavljajući Vam obavijest (npr. Putem e-maila, VerdiGo Aplikacije, ili VerdiGo Računa Vozača) u svakom slučaju kada se, prema mišljenju VerdiGo, radi o materijalnoj izmjeni.</p>
    <p>14.2. VerdiGo će Vam poslati obavijest najmanje 7 dana ranije (npr. putem e-pošte, VerdiGo Aplikacije ili VerdiGo Računa Vozača) o izmjenama koje utječu na prava poslovnih korisnika koji posluju u državi članici, osim ako:</p>
    <p>14.2.1. VerdiGo podliježe zakonskoj ili regulatornoj obvezi koja zahtijeva izmjenu Općih uvjeta na način koji mu ne dopušta poštivanje roka za slanje prethodne obavijesti;</p>
    <p>14.2.2. potrebna je trenutna izmjena radi rješavanja nepredviđene i neposredne opasnosti povezane sa zdravstvenim, sigurnosnim ili kiber sigurnosnim rizicima ili zaštite VerdiGo Usluga, putnika ili vozača od prijevare, zlonamjernog softvera, neželjene pošte ili povrede podataka;</p>
    <p>14.2.3. ako ste se odrekli prava na prethodnu obavijest (npr. nastavljate koristiti VerdiGo Usluge nakon primitka obavijesti o dopuni); ili 14.2.4. po razumnom mišljenju VerdiGo, izmjene su korisne za Vozače i ne zahtijevaju tehničke prilagodbe.</p>
    <p>14.3. Ako se ne slažete s izmjenama Općih uvjeta ili drugim uvjetima Ugovora, imate pravo raskinuti Ugovor prestankom korištenja VerdiGo Usluga i dostavljanjem obavijesti o raskidu VerdiGo. Raskid Ugovora stupa na snagu na dan stupanja na snagu predložene izmjene i dopune , osim ako nije drugačije navedeno u Vašoj obavijesti o raskidu. Korištenje VerdiGo Usluga na ili nakon datuma stupanja na snagu izmjena i dopuna predstavlja Vaš pristanak da budete vezani izmjenama Općih uvjeta ili Ugovora.</p>
    <h2 id="mjerodavno-pravo-i-sudska-nadležnost"><strong>15. MJERODAVNO PRAVO I SUDSKA NADLEŽNOST</strong></h2>
    <p>15.1. Opći uvjeti i Ugovor podliježu, tumače se i provode u skladu sa zakonima Republike Hrvatske.</p>
    <h2 id="obavijesti">16. OBAVIJESTI</h2>
    <p>16.1. Dužni ste nas odmah obavijestiti o svim promjenama u vezi s Vašim kontakt podacima.</p>
    <p>16.2. Bilo koja obavijest koja mora biti dana u skladu s Općim uvjetima i Ugovorom bit će propisno dana ako:</p>
    <p>16.2.1. je osobno dostavljena,</p>
    <p>16.2.2. poslana kurirskom službom uz potvrdu o dostavi,</p>
    <p>16.2.3. poslana preporučenom poštom,</p>
    <p>16.2.4. poslana e-poštom ili</p>
    <p>16.2.5. dostavljena putem VerdiGo Aplikacije ili VerdiGo Računa Vozača.</p>
    <p>16.3. Bilo koja obavijest koja je poslana ili odaslana u skladu s ovim Člankom smatrat će se primljenom:</p>
    <p>16.3.1. ako je dostavljena osobno, u vrijeme dostave ugovornoj strani,</p>
    <p>16.3.2. ako je dostavljena kurirskom službom, na datum koji dostavljačka služba navede kao datum kada je kuverta koja sadrži obavijest isporučena ugovornoj strani,</p>
    <p>16.3.3. ako je poslana preporučenom poštom, 10. dana nakon predaje dokumenta poštanskom uredu za dostavu ugovornoj strani,</p>
    <p>16.3.4. ako je dostavljena putem VerdiGo Aplikacije ili VerdiGo Računa Vozača, ili</p>
    <p>16.3.5. ako je poslana e-poštom, da dan kada ugovorna strana koja prima e-poštu potvrdi primitak predmetne e-pošte ili 2-og dana nakon što je e-pošta poslana pod uvjetom da pošiljatelj nije primio obavijest o pogrešci (kojom se obavještava da e-pošta nije dostavljena ugovornoj strani) i da je e-poštu poslao ponovno idućeg kalendarskog dana i nije primio sličnu obavijest o pogrešci.</p>
    <h2 id="završne-odredbe"><strong>17. ZAVRŠNE ODREDBE</strong></h2>
    <p>Ako se neka od odredaba Općih uvjeta bude smatrala neprovedivom, ugovorne strane zamijenit će zahvaćenu odredbu provedivom odredbom koja je najsličnija namjeri i ekonomskom učinku zahvaćene odredbe. Datum stupanja na snagu Općih uvjeta: 01.01.2023</p>

  </div>

</template>

<script>
export default {
name: "PrivacyView"
}
</script>

<style scoped>

.section {
  padding: 16px 16px;
  margin-top: 32px;
  background: #fff;
}

.section:first-child {
  margin-top: 0;
  background: transparent;
}


ul {
  list-style: none;
  padding: 4px;
  margin: 0;
  counter-reset: list-number;
}

ul li {
  counter-increment: list-number;
  margin-bottom: 8px;
  content: counter(list-number) ". ";
}

.table-of-contents ul li::before {
  content: none;
}

ul ul {
  padding-left: 16px;
}
ul ul li {
  margin-bottom: 0;
}
</style>
