import en from './en.json'
import hr from './hr.json'

export const defaultLocale = 'en'

export const languages = {
    en: en,
    hr: hr,
}


export const datetimeFormats = {
    en: {
        short: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        },
        long: {
            hour: '2-digit',
            minute: '2-digit',
            // second: '2-digit',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        },
    },
    hr: {
        short: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        },
        long: {
            hour: '2-digit',
            minute: '2-digit',
            // second: '2-digit',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        },
    },
}

export const numberFormats = {
    en: {
        currency: {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'symbol'
        }
    },
    hr: {
        currency: {
            style: 'currency',
            currency: 'HRK',
            currencyDisplay: 'symbol'
        }
    }
}