<template>
  <div class="min-h-screen the-gradient md:min-h-0">
    <div class="flex max-w-5xl py-8 m-auto md:py-16 gap-x-28">
      <div class="flex flex-col justify-between flex-1 text-white">
        <div class="">
          <h2 class="mb-4 text-4xl font-bold md:mb-8 md:text-6xl">{{ $t('app_showcase.app') }}</h2>
          <div class="mb-4 overflow-hidden h-60 md:hidden">
            <img
              :src="tabs[activeTab].image"
              alt=""
              class="h-full transform drop-shadow-md"
            />
          </div>
          <div class="flex mb-4 md:hidden gap-x-2">
            <button
              v-for="(tab, index) in tabs"
              :key="index"
              @click="() => setTab(index)"
              class="flex items-center justify-center border border-white rounded-full h-11 w-11"
              :class="{ 'text-secondary-30 bg-white': activeTab === index }"
            >
              {{ index + 1 }}
            </button>
          </div>
          <h3 class="mb-2 text-2xl font-bold">
            {{ $t(tabs[activeTab].title) }}
          </h3>
          <p class="text-smtext-opacity-80 mb-11">
            {{ $t(tabs[activeTab].description) }}
          </p>
        </div>

        <div class="hidden md:flex gap-x-2">
          <button
            v-for="(tab, index) in tabs"
            :key="index"
            @click="() => setTab(index)"
            class="flex items-center justify-center border border-white rounded-full h-11 w-11"
            :class="{ 'text-secondary-30 bg-white': activeTab === index }"
          >
            {{ index + 1 }}
          </button>
        </div>
      </div>
      <div class="items-center justify-center flex-1 hidden md:flex h-96">
        <img
          :src="tabs[activeTab].image"
          alt=""
          class="object-cover h-full transform drop-shadow-md"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const tabs = [
  {
    //  app_showcase.showcase.showcase_1.title
    //  app_showcase.showcase.showcase_1.description
    title: 'app_showcase.showcase.showcase_1.title',
    description:
      'app_showcase.showcase.showcase_1.description',
    image: require('@/assets/images/app-showcase-1.png'),
  },
  {
    title: 'app_showcase.showcase.showcase_2.title',
    description:
      'app_showcase.showcase.showcase_2.description',
    image: require('@/assets/images/app-showcase-2.png'),
  },
  {
    title: 'app_showcase.showcase.showcase_3.title',
    description:
      'app_showcase.showcase.showcase_3.description',
    image: require('@/assets/images/app-showcase-3.png'),
  },
]
const activeTab = ref(0)
function setTab(index) {
  activeTab.value = index
}
</script>

<style>
.the-gradient {
  background: linear-gradient(270.61deg, #00363e 0%, #006876 101.28%);
}
</style>
