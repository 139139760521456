import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import { createI18n } from 'vue-i18n'
import {
    datetimeFormats,
    languages,
    numberFormats,
    defaultLocale,
} from './locales'



export const i18n = createI18n({
    legacy: false,
    locale: defaultLocale,
    fallbackLocale: 'en',

    messages: languages,
    datetimeFormats: datetimeFormats,
    numberFormats: numberFormats,
})


createApp(App).use(i18n).use(store).use(router).mount('#app')
