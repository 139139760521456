<template>
  <div class="flex border-t border-gray-300 hover:bg-neutral-100 hover:cursor-pointer" @click="toggleOpen">
    <div class="py-4 text-secondary-40">
      <span class="">+</span>
    </div>
    <div class="flex-1">
      <div class="p-4">{{ props.question }}</div>
      <div
        class="max-w-md px-4 overflow-hidden text-sm transition-all text-black/60 "
        :class="{ 'max-h-0': !isOpen, 'max-h-80 pb-4': isOpen }"
      >
        {{ props.answer }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'

const props = defineProps({
  question: {
    type: String,
    required: true
  },
  answer: {
    type: String,
    required: true
  },
  initialOpen: {
    type: Boolean,
    default: false
  }
})

var isOpen = ref(props.initialOpen)

const toggleOpen = () => {
  isOpen.value = !isOpen.value
}
</script>

<style></style>
