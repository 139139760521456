<script setup>
import MainFooter from './components/MainFooter.vue'
import PWA from '@/components/PWA.vue'

import { onMounted, onUnmounted, ref } from 'vue'
import LanguageSelect from './components/LanguageSelect.vue'

const showSignupBtn = ref(false)
function updateShowSignupBtn() {
  showSignupBtn.value =
    document.documentElement.scrollTop > document.documentElement.clientHeight
}
onMounted(() => {
  window.addEventListener('scroll', updateShowSignupBtn)
})
onUnmounted(() => {
  window.removeEventListener('scroll', updateShowSignupBtn)
})

function goToSignup() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}
</script>

<template>
  <div class="flex flex-col">
    <nav
      class="fixed top-0 z-50 w-full h-16 text-white"
      :class="{ 'bg-white shadow': showSignupBtn }"
    >
      <div
        class="flex items-center justify-between h-full px-4 py-2 pr-8 m-auto max-w-7xl"
      >
        <router-link
          to="/"
          class="text-2xl font-bold"
          :class="{ 'filter brightness-0 saturate-100': showSignupBtn }"
        >
          <!-- Verdi
        <span :class="[showSignupBtn ? 'text-secondary-50' : '']">GO</span> -->
          <img src="@/assets/images/logo-type.svg" alt="" />
        </router-link>
        <div class="flex gap-4">
          <LanguageSelect class="md:block" :class="{'text-black': showSignupBtn}" />
          <button
            v-if="showSignupBtn"
            @click="goToSignup"
            class="flex items-center pl-4 pr-2.5 py-2 text-xl text-white rounded-md bg-secondary-50 bg-gradient-to-r from-secondary-50 via-secondary-50 to-secondary-40"
          >
            {{ $t('navbar.sign_up') }}
            <!-- <span class="ml-2 material-symbols-outlined"> person_add </span> -->
            <!-- <span class="ml-2 material-symbols-outlined"> how_to_reg </span> -->
            <span class="pt-1 ml-2 material-symbols-outlined animate-bounce">
              arrow_upward
            </span>
          </button>
        </div>
      </div>
      <!-- <router-link to="/about">About</router-link> -->
    </nav>
    <router-view class="flex-1 min-h-screen" />
    <PWA class="px-4 snap-none" />
    <MainFooter class="px-2 snap-end" />
  </div>
</template>

<style>
html,
body {
  height: 100%;
  width: 100%;
  @apply snap-proximity snap-y;
  /* overflow: hidden; */
}
#app {
  font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100%;
  /* overflow-y: auto; */
}
</style>
